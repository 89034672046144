html,
body {
  font-family: -apple-system, /** Safari for OS X and iOS (San Francisco) **/
  BlinkMacSystemFont, /** Chrome < 56 for OS X (San Francisco) **/
  "Segoe UI", /** Windows **/
  Roboto, /** Android **/
  "Helvetica Neue", /** Basic web fallback **/
  Arial,
  sans-serif;
  margin: 0;
}

#app {
  height: 100vh;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'header' 'main' 'footer';

  > header {
    grid-area: header;
  }

  > main {
    grid-area: main;
  }

  > footer {
    grid-area: footer;
    background-color: rgb(238, 238, 238);
  }
}
