.schema-tree.e-treeview .e-list-text {
  width: 100%;
}

.schema-tree.e-treeview .textcontent {
  float: left;
}

.schema-tree.e-treeview.e-rtl .textcontent {
  float: right;
}

.schema-tree.e-treeview .commandcontainer {
  float: right;
  margin-right: 5px;
  pointer-events: auto;
}

.bootstrap5.e-bigger .schema-tree.e-treeview .commandcontainer .treeCount.e-badge,
.bootstrap5-dark.e-bigger .schema-tree.e-treeview .commandcontainer .treeCount.e-badge {
  line-height: 11px;
}

.schema-tree.e-treeview.e-rtl .commandcontainer {
  float: left;
  margin-left: 5px
}

.material .treeCount.e-badge {
  vertical-align: middle;
}

.bootstrap4 .treeviewdiv .treeCount.e-badge,
.bootstrap5 .treeviewdiv .treeCount.e-badge,
.bootstrap5-dark .treeviewdiv .treeCount.e-badge,
.bootstrap .treeviewdiv .treeCount.e-badge,
.bootstrap-dark .treeviewdiv .treeCount.e-badge,
.highcontrast .treeviewdiv .treeCount.e-badge {
  background-color: #008800;
}

.highcontrast .treeviewdiv .treeCount.e-badge {
  color: white;
}

.schema-tree.e-treeview.e-interaction.e-fullrow-wrap .e-text-content {
  pointer-events: none;
}
